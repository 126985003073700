import "./pagess.css"
import React from "react";
import Lakes from './images.jsx'
import { SliderData } from './data/SliderData.js';
function About() {
    return (
        <div className="gpt1__header section__padding" id="home">


            <div className="gpt1__header-content">
                <h1 className="gradient__text" >
                    About
                </h1>
                <p>
                    Inspired by the ever quickly growing EDM space, founder D2 determined producing EDM events is a passion they wished to pursue to a greater extent.
                    D2P has three core functions: Planning, Management, and Production.
                    <br />
                    <br />
                    Planning: Planning is one of the core elements of bringing your event from idea to execution. Everything from theme and ambiance to finances and talent bookings must be planned prior to the event to ensure a great experience for the attendees. D2P prides itself in its attention to detail and leaving no page left unturned when arranging for an event.
                    <br />
                    <br />
                    Management: Venue reps, artists, security, beverage distributors, decorators, performers, photographers are just a few of the individuals or organizations that must be dealt with in order to organize a successful event. Management of these parties, including negotiating contractual agreements and supporting them during the event is D2P’s priority before, during, and after the show.
                    <br />
                    <br />
                    Production: The main elements of production such as lighting and audio/visual effects are considered by and taken care of D2P in house. No matter the size of the event, the scale at which D2P operates can adjust. Having an effective lighting scene as well as above adequate acoustic consideration for an event means attendees will always be pleasantly surprised by the production quality.


                </p>

            </div>
            <div className="gpt1__header-image">
                <img src="lasers.jpg" />
            </div>
        </div>

    )
}

export default About;