import "./pagess.css"
import React from "react";
import Lakes from './images.jsx'
import { SliderData } from './data/SliderData.js';
function Merch() {
    return (
        <div className="gpt1__header section__padding" id="home">


            <div className="gpt1__header-content">
                <h1 className="gradient__text" >
                    Merch
                </h1>
                <p style={{ textAlign: "Center" }}>
                    Coming Soon.</p>
            </div>
        </div>

    )
}

export default Merch;