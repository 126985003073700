import React from 'react';
import './Footer.css';

const Footer = () => (
    <div className="gpt3__footer section__padding">

        <div className="gpt3__footer-links">
            <div className="gpt3__footer-links_div">
                <a href="/"><h4 className="gradient__textss" style={{ fontSize: '25px' }} >
                    D:2P
                </h4></a>
                <p style={{ fontFamily: "Quicksand, sans-serif", fontSize: '15px' }}>Chicago <br /><br /> USA</p>
            </div>
            <div className="gpt3__footer-links_div">
                <h4 style={{ fontSize: '20px' }}>Links</h4>
                <p><a href="https://www.instagram.com/d2p_ent/?hl=en" target="_blank"><i class="fa-brands fa-instagram"></i> Instagram</a></p>
            </div>
            <div className="gpt3__footer-links_div">
                <h4 style={{ fontSize: '20px' }}>Navigation</h4>
                <p style={{ fontFamily: "Quicksand, sans-serif" }}><a href="/">Home</a></p>
                <p style={{ fontFamily: "Quicksand, sans-serif" }}><a href="/about">About</a></p>
            </div>
            <div className="gpt3__footer-links_div">
                <h4 style={{ fontSize: '20px' }}>Get in touch</h4>
                <p style={{ fontFamily: "Quicksand, sans-serif" }}>630-706-0508</p>
                <p><a href="mailto:Info@D2P-ENT.com">INFO@D2P-ENT.COM</a></p>
            </div>
        </div>

        <div className="gpt3__footer-copyright">
            <p>&#169; 2022 D2P Entertainment. All rights reserved.</p>
        </div>
    </div>
);

export default Footer;