import "./HomeStyles.css"
import React from "react";
import Lakes from './images.jsx'
import { SliderData } from './data/SliderData.js';
function Home() {
    return (
        <div className="valdas">
            <div className="gpt3__header section__padding" id="home">
                <div className="gpt3__header-content" style={{ marginTop: "10%" }}>
                    <p style={{ marginTop: "8rem", marginBottom: "0rem", fontFamily: 'Videotype', fontSize: "30px", color: "var(--color-text)" }}>
                        New Event! Go to <a style={{ fontFamily: 'Videotype' }} href="/events" target="_blank" ><u style={{ fontFamily: 'Videotype' }}>events page</u></a>!!!
                    </p>
                    <h1 className="gradient__text" >
                        D2P
                    </h1>
                    <p>
                        Planning &nbsp;&nbsp;Management &nbsp;  Production
                    </p>
                    <p ><a href="https://www.instagram.com/d2p_ent/?hl=en" target="_blank" ><i class="fa-brands fa-instagram" style={{ fontSize: "25px", }}></i> <div style={{ fontFamily: 'var(--font-family)', display: "inline", fontSize: "35px" }}>D2P_ENT</div></a></p>
                </div>
            </div>
        </div >

    )
}

export default Home;