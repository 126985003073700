import "./pagess.css"
import React from "react";
import Lakes from './images.jsx'
import { SliderData } from './data/SliderData.js';
function Events() {
    return (
        <div className="gpt3__header section__padding" id="home">
            <div className="gpt1__header-content" style={{ marginTop: "4rem" }}>
                <h1 className="gradient__text" >
                    Events
                </h1>
                <p style={{ textAlign: "center" }}>
                    02.25.2023
                </p>
                <br />
                <p style={{ textAlign: "center" }}>
                    ELEMENTS will be a celebration of EDM music in Chicago. From new talent to experienced acts, the night will be full of blooming energy.
                </p>
                <br />
                <p style={{ textAlign: "center", textDecoration: "underline" }}> <a style={{ fontFamily: 'Videotype' }} href="https://www.eventbrite.com/e/elements-an-edm-celebration-tickets-482852343357" target="_blank" >
                    Click Here for Ticket Portal
                </a>
                </p>

                <div className="gpt1__header-image">
                    <img src="elements.png" />
                </div>
            </div>
        </div >

    )
}

export default Events;