export const MenuData = [
    {
        title: "Home",
        url: "/",
        cName: "nav-links",
        icon: "fa-regular fa-circle-down"
    },
    {
        title: "About",
        url: "/about",
        cName: "nav-links",
        icon: "fa-solid fa-circle-info"
    },
    {
        title: "Services",
        url: "/services",
        cName: "nav-links",
        icon: "fa-solid fa-umbrella"
    },
    {
        title: "Events",
        url: "/events",
        cName: "nav-links",
        icon: "fa-solid fa-ticket"
    },
    {
        title: "Merch",
        url: "/merch",
        cName: "nav-links",
        icon: "fa-solid fa-shirt"
    },
    {
        title: "Contact",
        url: "/contact-us",
        cName: "nav-links",
        icon: "fa-brands fa-stack-overflow"
    },

];