import React from 'react';
import "./index.css"
import Navbar from "./components/Navbar"
import Particle from './components/Particles';
import Home from './components/Home';
import Services from './components/services';
import Events from './components/events';
import Merch from './components/merch';
import About from './components/About';
import Footer from './components/Footer.jsx';
import ContactUs from './components/ContactUs';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HashRouter } from 'react-router-dom'
function App() {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <div className='particles'>
          <Particle />
        </div>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/services' element={<Services />} />
          <Route path='/about' element={<About />} />
          <Route path='/events' element={<Events />} />
          <Route path='/merch' element={<Merch />} />
        </Routes>
      </Router>
      <Footer />
    </div>

  );
}

export default App;
