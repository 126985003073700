import React from 'react';
import './main.scss';
import styled, { css } from 'styled-components/macro';
import { EmailJSResponseStatus } from 'emailjs-com';
import emailjs from 'emailjs-com'
import sample from './cdjs.mp4';
function ContactUs() {
    function sendEmail(e) {
        e.preventDefault();
        alert('Message sent')
        emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, e.target, process.env.REACT_APP_EMAIL_USER_ID
        ).then(res => {
            console.log(res)
        }).catch(err => console.log(err))
    }

    return (
        <div>
            <div class="container">
                <div class="contact-box">
                    <div class="left"><video className='videoTag' autoPlay loop muted playsInline>
                        <source src={sample} type='video/mp4' />
                    </video>

                    </div>
                    <div class="right">
                        <h2 >Contact Us</h2>
                        <form onSubmit={sendEmail}>
                            <input type="text" class="field" placeholder="Your Name" name="name" required></input>
                            <input type="text" class="field" placeholder="Your Email" name="email" required></input>
                            <input type="text" class="field" placeholder="Phone" name="phone"></input>
                            <textarea placeholder="Message" class="field" name="message" required></textarea>
                            <button class="btn" type="submit" value="Send" >Send</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="gpt3__footer-heading">
                <h1 className="gradient__text">Lets Mix</h1>
            </div>
        </div>

    )
};

export default ContactUs;