import "./pages.css"
import React from "react";
import Lakes from './images.jsx'
import { SliderData } from './data/SliderData.js';
function Services() {
    return (
        <div className="gpt2__header section__padding" id="home">
            <div className="gpt2__header-content">
                <h1 className="gradient__text" >
                    Services
                </h1>
                <p>
                    D2P’s main market is the city of Chicago and surrounding areas. With growth the company would like to scale to provide our outstanding service worldwide.
                    <br />
                    <br />
                    Concerts:
                    <br />
                    D2P plans, contracts, and executes all aspects necessary to create a concert experience that will leave attendees with memories that will last a lifetime.
                    <br />
                    <br />
                    Private events:
                    <br />
                    Corporate events, club events, birthdays, weddings, and more all fall under the umbrella of D2P’s private event capabilities. When it comes to entertainment at such an event, D2P will help facilitate your vision coming to fruition.

                </p>
            </div>
            <div className="gpt2__header-image">
                <img src="danowesmyguap.jpg" />
            </div>
        </div>

    )
}

export default Services;