import { Component } from "react";
import "./NavbarStyles.css";
import { MenuData } from "./MenuData.js";


class Navbar extends Component {
    state = { clicked: false };
    handleClick = () => { this.setState({ clicked: !this.state.clicked }) }
    render() {
        return (
            <nav className="NavbarItems">
                <div className="imgStyles">
                    <a href="/"> <h1 className="gradient__texts" style={{
                        fontFamily: 'var(--font-family)'
                    }}>
                        D:2P
                    </h1></a>
                </div>
                <div className="menu-icons" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fa-sharp fa-solid fa-bars"}></i>
                </div>
                <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                    {MenuData.map((item, index) => {
                        return (
                            <li key={index}>
                                <a href={item.url} className={item.cName}>
                                    <i className={item.icon}></i> {item.title}
                                </a>
                            </li>
                        )
                    })}

                </ul>
            </nav >
        )
    }
}

export default Navbar;